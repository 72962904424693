import { useEffect } from "react";
import LoadingIndication from "./LoadingIndication";
import { redirectToPage } from "./utils/redirect";

function App() {
  useEffect(() => {
    setTimeout(() => {
      redirectToPage();
    }, 1000);
  }, []);

  return (
    <div className="container">
      <LoadingIndication />
    </div>
  );
}

export default App;
