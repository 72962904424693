import {devRedirects, prodRedirects, Redirects} from "./redirectPages";
import * as LDClient from "launchdarkly-js-client-sdk";
import {Redirect} from "../types/redirect";

const getLocationSearch = () => {
  return window.location.search.replaceAll("%3D", "=").replaceAll("%26", "&");
};

export const redirectToPage = async () => {
  const redirectPage = await getRedirectPage();
  if (redirectPage) {
    window.location.href = redirectPage + getLocationSearch();
  }
};

const getRedirectPage = async () => {
  const redirects = isDevEnvironment() ? devRedirects : prodRedirects;
  return await findRedirectPage(redirects);
};

const isDevEnvironment = () => {
  const { queryParams } = getQueryParams();
  return queryParams.get("funnel_mode") === "test";
};

const getQueryParams = () => {
  let urlSearch = getLocationSearch();
  let queryParams = new URLSearchParams(urlSearch);
  if (!queryParams.get("funnel_start")) {
    queryParams.set("funnel_start", "getstarted");
    urlSearch = queryParams.toString();
    window.history.replaceState(null, "", `?${urlSearch}`);
  }
  return { urlSearch, queryParams };
};

enum Plans {
  Individual = "Individual",
  Couple = "Couple",
}

const findRedirectPage = async (redirects: Redirects) => {
  const { queryParams } = getQueryParams();

  let funnelStart = queryParams.get("funnel_start") || "NA";
  let primary_member_id = queryParams.get("primary_member_id") || "NA";
  let uuid = queryParams.get("uuid") || "NA";
  const email = queryParams.get("email") || "NA";
  const utmSource = queryParams.get("utm_source" || "NA");
  const subscription = queryParams.get("subscription");
  const initialAmount = queryParams.get("initial_amount");
  const price = queryParams.get("price");

  let redirect = Redirect.PRICING;

  if (utmSource === Redirect.USER_RESEARCH) {
    redirect = Redirect.USER_RESEARCH;
  } else if (funnelStart === "partner_activation") {
    redirect = Redirect.WEBAPP;
  } else if (primary_member_id !== "NA") {
    redirect = Redirect.WEBAPP;
  } else if (funnelStart === "pricing") {
    redirect = Redirect.CHECKOUT;
  }

  if (redirect === Redirect.PRICING) {
    const context: LDClient.LDContext = {
      kind: "user",
      key: uuid,
      email,
    };
    const client = LDClient.initialize(
      process.env.REACT_APP_LAUNCH_DARKLY_ID as string,
      context,
    );
    await client.waitUntilReady();
    const show_ty_page = client.variation("show_ty_page");

    if (show_ty_page) {
      redirect = Redirect.THANK_YOU;
    } else {
      if (subscription) {
        if (subscription === Plans.Individual) {
          redirect = Redirect.PRICING_INDIVIDUAL;
        } else if (subscription === Plans.Couple) {
          redirect = Redirect.PRICING_COUPLE;
        }
      }
    }

    if (initialAmount && price) {
      redirect = Redirect.CHECKOUT;
    }
  }

  return redirects[redirect];
};
